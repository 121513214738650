html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(/public/bg.jpg);
  --primary: rgba(39, 158, 163, 1);
  --primary-transparent: rgba(39, 158, 163, .75);
  --focus: red;
  --app-height: calc(100vh - calc(100vh - 100%));
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div#root {
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  justify-items: center;
  align-items: center;
  height: var(--app-height);
  box-sizing: border-box;
  padding: 1rem;
}

div.Card {
  backdrop-filter: blur(3px);
  padding: 15px;
  background-color: var(--primary-transparent);
  box-sizing: border-box;
  border-radius: 15px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, .5);
  display: grid;
  height: calc(var(--app-height) - 2rem);
  grid-template-rows: auto 1fr auto;
}

div.Card p {
  color: #888;
}


div.Card.divider h1 {
  color: #888;
}

div.Card.divider img {
  filter: drop-shadow(0px 0px 3px var(--primary));
}

div.Card.font h1 {
  text-shadow: 0px 0px 5px var(--primary);
}

div.Card.font img {
  opacity: .5;
}

div.Card div.Category {
  text-align: center;
  text-transform: capitalize;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: calc(10px + .2em);
  padding-top: .2em;
}

div.Card div.Content {
  background: #f8f8f8;
  filter: sepia(10%);
  box-shadow: inset 0px 0px 15px rgba(0, 0, 0, .75);
}

div.Card h1 {
  transition: all 0.25s ease-in-out;
  font-size: 2.75rem;
  padding: 1rem 2rem;
  line-height: 1.1em;
  word-wrap: break-word;
  font-weight: normal;
  text-align: center;
  margin-bottom: .5rem;
}

div.Card img {
  width: calc(100% - 4rem);
  object-fit: contain;
  height: 3rem;
  margin: .5rem 2rem 1rem;
}

div.Card p {
  transition: all 0.25s ease-in-out;
  padding: 0 2rem;
  font-family: serif;
  font-size: 14pt;
  text-align: left;
  font-weight: normal;
  text-align: justify;
}

div.Card .buttons {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding-top: 10px;
}

div.Card .buttons button {
  transition: all 0.5s ease-in-out;
  font-size: 2rem;
  border: none;
  background: none;
  transform: scale(1.25);
  filter: drop-shadow(0px 0px 5px rgba(0, 80, 80, 1));
}

div.Card .buttons button:active {
  text-shadow: 0px 0px 10px lightblue;
}